import axios from "axios";
import React, { useState } from "react";
import jwt_decode from "jwt-decode";
import { Mixpanel } from "../Mixpanel";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Loader from "react-loader-spinner";
import moment from "moment";
import * as yup from "yup";
import { useFormik } from "formik";
const WorkExperienceForm = (props) => {
  const [isLoader, setIsLoader] = useState(false);
  localStorage.setItem("navState", 10);
  const [isProfileFromCP, setIsProfileFromCP] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [location, setLocation] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [earlierDateError, setEarlierDateError] = useState(false);
  const [description, setDescription] = useState("");
  const [current_job, setCurrentJob] = useState(true);
  const [type, setType] = useState("text");
  const [types, setTypes] = useState("text");
  const date = new Date();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  let loading = open && options.length === 0;
  const searchHandler = async (value) => {
    let active = true;
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_PATH}/company?search=${value}`
    );
    const items = response.data;
    if (!loading && items.length === 0) {
      return undefined;
    }
    if (active) {
      setOptions([...items]);
    }

    return () => {
      active = false;
    };
  };

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  const AddExperience = () => {

    setIsLoader(true);
    if (+startDate > +new Date() || +endDate > +new Date()) {
      setEarlierDateError(true);
      setIsLoader(false);
      return;
    }
    setEarlierDateError(false);
    const Data = {
      user_id: jwt_decode(localStorage.getItem("customer_signup_token")).id,
      company: companyName,
      employement_type: "",
      image: "",
      job: jobTitle,
      location: location,
      title: jobTitle,
      current_job: current_job,
      start_date: `${startDate.getFullYear()}-${startDate.getMonth() + 1}`,
      end_date: current_job
        ? `${endDate.getFullYear()}-${endDate.getMonth() + 1}`
        : `${date.getFullYear()}-${date.getMonth() + 1}`,
      description: description,
      status: "",
    };
    axios
      .post(`${process.env.REACT_APP_SERVER_PATH}/experience`, Data)
      .then((response) => {
        Mixpanel.track(`web: Add Experience.`, Data);
        if (!isProfileFromCP) {
          const slug = response.data?.company
            ?.replace(/\s+/g, "-")
            .toLowerCase();
          axios
            .post(
              `${process.env.REACT_APP_SERVER_PATH}/company`,
              {
                name: response.data?.company,
                slug: slug,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization:
                    "Bearer " + localStorage.getItem("customer_signup_token"),
                },
              }
            )
            .then(() => {
              console.log("comapny has been added");

            });
        }
        setIsLoader(false);
        props.setNavState(props.navState + 1);
      })
      .catch((e) => {
        console.log(e);

      });
  };

  //

  //
  const handleStartDate = (e) => {
    setStartDate(new Date(e.target.value));
    //  setStartDate(moment(e.target.value).format("MM YYYY"));
 
  };
  const handleEndDate = (e) => {
    setEndDate(new Date(e.target.value));
    // setEndDate(moment(e.target.value).format("MM YYYY"));
    // const selectedDate = e.target.value;
    // setEndDate(selectedDate);
    // if (selectedDate < startDate) {
    //   setEarlierDateError(true);
    // } else {
    //   setEarlierDateError(false);
    // }
  };


  const DropdownWithButton = ({ children, ...other }) => (
    <Paper {...other}>
      {companyName && (
        <div className="flex w-full items-center hover:bg-gray-300 bg-gray-50 cursor-pointer">
          <Button
            sx={{
              width: "100%",
              textTransform: "none",
              padding: 1,
              justifyContent: "flex-start",
            }}
            onMouseDown={(e) => {
              e.preventDefault();
              setIsProfileFromCP(false);
              setOpen(false);
              document.querySelector(`input[id=job]`).focus();
            }}
          >
            <img
              src="/educationplaceholder.png"
              alt="educationplaceholder"
              className="flex-shrink-0 h-6 w-6 rounded-full"
            />
            <p className="ml-3 block font-normal truncate">
              + Add Company: {companyName}
            </p>
          </Button>
        </div>
      )}
      {children}
    </Paper>
  );

  return (
    <div className="font-bold text-xs w-full text-textFullDark">
      <div className="flex relative w-full items-center justify-center">
        <div className="-mt-2">
          <button
            className="absolute left-0"
            onClick={() => {
              props.setNavState(props.navState - 1);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-arrow-left"
              viewBox="0 0 16 16"
            >
              {" "}
              <path
                fill-rule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
              />{" "}
            </svg>{" "}
          </button>
        </div>

        <p className="text-2xl font-bold mt-3 text-textFullDark">
          Add work experience
        </p>
      </div>

      <div className="mb-4 mt-4">
        <label className="block text-textFullDark mb-2" for="company">
          Company
        </label>
        <Autocomplete
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          getOptionLabel={(option) => option.name ?? option}
          options={options}
          freeSolo={true}
          PaperComponent={DropdownWithButton}
          renderOption={(props, option) => (
            <div
              {...props}
              className="flex items-center p-2 hover:bg-gray-200 bg-gray-50 cursor-pointer"
            >
              <img
                src={
                  option?.profile_photo
                    ? option?.profile_photo
                    : "/educationplaceholder.png"
                }
                alt={option?.name}
                className="flex-shrink-0 h-6 w-6 rounded-full"
              />
              <span className="ml-3 block font-normal truncate">
                {option?.title}
                {option?.name}
              </span>
            </div>
          )}
          sx={{ alignItems: "center" }}
          clearOnBlur={false}
          onInputChange={(e, value) => {
            setCompanyName(value);
          }}
          onChange={(e, obj) => {
            if (obj) {
              obj.name ? setCompanyName(obj.name) : setCompanyName(obj);
              setIsProfileFromCP(true);
              document.querySelector(`input[id=job]`).focus();
            }
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setCompanyName(e.target.value);
              setIsProfileFromCP(false);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              id="company"
              type="text"
              placeholder="Company"
              // value={companyName}
              onChange={(e) => {
                setCompanyName(e.target.value);
                searchHandler(e.target.value);
              }}
              defaultValue={companyName}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </div>
      <div className="mb-4">
        <label className="block text-textFullDark mb-2" for="job">
          Job
        </label>
        <input
          className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textFullDark  focus:outline-none"
          id="job"
          type="text"
          placeholder="Job title"
          value={jobTitle}
          onChange={(e) => setJobTitle(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="block text-textFullDark mb-2" for="location">
          Location <span className="text-red-500">*</span>
        </label>
        <input
          className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textFullDark  focus:outline-none"
          id="location"
          type="text"
          placeholder="Location"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          required=""
        />
      </div>
      <div className="flex w-full justify-between">
        <div className="w-1/2">
          <label className="block text-textFullDark mb-2" for="firstName">
            Start Date
          </label>
          <input
            className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textFullDark focus:outline-none"
            id="firstName"
            type={types}
            onChange={handleStartDate}
            // max={new Date().toISOString().split("T")[1]}
            max={endDate || new Date().toISOString().split("T")[0]}
            placeholder="MM/YY"
            onFocus={() => setTypes("month")}
            onBlur={() => setTypes("text")}
          />

          {earlierDateError && (
            <p className="text-sm text-red-500 my-1">
              Date should be today or earlier
            </p>
          )}
        </div>
        {current_job ? (
          <div className="ml-2 w-1/2">
            <label className="block text-textFullDark mb-2" for="firstName">
              End Date
            </label>
            <input
              className="text-sm border rounded w-full border-borderGray py-3 px-3 text-textFullDark  focus:outline-none"
              id="firstName"
              type={type}
              minDate={new Date()}
              placeholder="MM/YY"
              onFocus={() => setType("month")}
              onBlur={() => setType("text")}
              onChange={handleEndDate}
              min={startDate || new Date().toISOString().split("T")[0]}
              max={new Date().toISOString().split("T")[1]}
              // disabled={!startDate}
            />
          </div>
        ) : null}
      </div>
      <div className="flex mb-4 items-center mt-4">
        <input
          type="checkbox"
          value={current_job}
          onChange={() => {
            setCurrentJob(!current_job);
            setEndDate("");
          }}
        />
        <p className="ml-3 text-xs text-gray-500 font-light">
          Currently working
        </p>
      </div>
      <div className="my-4 w-full text-xs font-bold">
        <label className="block text-textFullDark  mb-2" for="description">
          Description
        </label>
        <textarea
          className="resize-none border border-borderGray rounded-md  w-full h-24 py-3 px-3 text-textFullDark  focus:outline-none"
          id="description"
          type="text"
          maxLength={280}
          placeholder="Description"
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        ></textarea>
        <p className="text-sm text-green-500">
          {280 - description.length} characters remaining
        </p>
      </div>
      <button
        className="w-full py-2 flex items-center justify-center text-sm rounded-lg text-white"
        style={{
          backgroundColor:
            companyName?.trim() === "" ||
              jobTitle.trim() === "" ||
              location.trim() === "" ||
              description.trim() === ""
              ? "grey"
              : "#00C4FF",
        }}
        disabled={
          companyName?.trim() === "" ||
          jobTitle.trim() === "" ||
          location.trim() === "" ||
          description.trim() === ""
        }
        onClick={AddExperience}
      >
        <p className="mr-4">Save</p>
        {isLoader && (
          <Loader height={15} width={15} color="white" type="Oval" />
        )}
      </button>
    </div>
  );
};

export default WorkExperienceForm;
