import axios from "axios";
import React, { useEffect, useState } from "react";
import BookingRequests from "../BookingRequests/BookingRequests";
import UpComingCalls from "./UpComingCalls/UpComingCalls";
import Loader from "react-loader-spinner";
import Colors from "../../../Colors/Colors";
import ReactDOMServer from "react-dom/server";
import ReactTooltip from "react-tooltip";
import { Helmet } from "react-helmet";
import { Mixpanel } from "../../../Mixpanel";
import { CompanyTitle } from "../../../settings";
const Home = (props) => {
  const type = JSON.parse(localStorage.getItem("type"));
  console.log(typeof type, "typeof type");
  const [isLoading, setLoading] = useState(false);
  const [bookingRequests, setBookingRequests] = useState([]);
  const [upCommingCalls, setUpcommingCalls] = useState([]);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [isProfileComplete, setIsProfileComplete] = useState({ isProfileComplete: true });
  useEffect(() => {
    setLoading(true);
    // API call for checking that the user has any meeting or not.
    axios
      .get(`${process.env.REACT_APP_SERVER_PATH}/bookings`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
        },
      })
      .then((response) => {
        if (response.data.message.length > 0) {
          setIsFirstTime(false);
        } else {
          setIsFirstTime(true);
        }
        setLoading(false);
      });

    axios
      .get(`${process.env.REACT_APP_SERVER_PATH}/is_mentor_profile_complete/${localStorage.getItem("id")}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
        },
      })
      .then((response) => {
        if (response.data.message) {
          setIsProfileComplete(response.data.message);
        }
        setLoading(false);
      });

    axios
      .get(
        `${process.env.REACT_APP_SERVER_PATH}/bookings?status=approved&limit=100`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
          },
        }
      )
      .then((response) => {
        setUpcommingCalls(response.data.message);
        console.log(response.data.message, "response.data.message");
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_PATH}/bookings?status=pending&limit=100`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("cp_web_token"),
          },
        }
      )
      .then((response) => {
        setBookingRequests(response.data.message);
        console.log(response.data.message, "response.data.message");
        setLoading(false);
      });
  }, []);
  Mixpanel.track(`web: open user dashboard.`);
  let ComponentToRender = null;
  if (upCommingCalls.length === 0 && bookingRequests.length === 0) {
    ComponentToRender = (
      <div className="w-full p-8">
        {isFirstTime ? (
          <div>
            <p className="text-center text-textApp">
              This is the very beginning of your {CompanyTitle} booking journey
            </p>
            <p className="text-center flex flex-col xl:flex-row items-center justify-center w-full text-xs mt-4 text-textFullDark">
              <p>Your meeting schedule will appear here</p>
              {type !== "professional" && <p>&nbsp;when you schedule your first session. </p>}
            </p>
          </div>
        ) : (
          <div>
            <p className="text-center text-textApp">
              Your confirmed bookings will appear here.{" "}
            </p>
            <p className="text-center flex-col xl:flex-row items-center justify-center w-full text-xs mt-4 text-textFullDark">
              <p>Your meeting schedule will appear here</p>
              {type !== "professional" && <p>&nbsp;when you schedule your session. </p>}
            </p>
          </div>
        )}
      </div>
    );
  } else if (upCommingCalls.length > 0 && bookingRequests.length === 0) {
    ComponentToRender = (
      <UpComingCalls
        user={props.user}
        upCommingCalls={upCommingCalls}
        setBookingNavState={props.setBookingNavState}
        setNavState={props.setNavState}
      />
    );
  } else if (upCommingCalls.length === 0 && bookingRequests.length > 0) {
    ComponentToRender = (
      <BookingRequests
        user={props.user}
        bookingRequests={bookingRequests}
        setBookingNavState={props.setBookingNavState}
        setNavState={props.setNavState}
      />
    );
  } else {
    ComponentToRender = (
      <div>
        <UpComingCalls
          user={props.user}
          upCommingCalls={upCommingCalls}
          setBookingNavState={props.setBookingNavState}
          setNavState={props.setNavState}
        />
        <BookingRequests
          user={props.user}
          bookingRequests={bookingRequests}
          setBookingNavState={props.setBookingNavState}
          setNavState={props.setNavState}
        />
      </div>
    );
  }
  return (
    <div>
      {type === "professional" && (
        <>
          <div>
            {props.user?.User?.deactivate === 1 ? (
              <div
                className="bg-yellow-400 rounded flex items-center p-3 mb-2"
                style={{ fontFamily: "Lato Bold" }}
              >
                {/* <img src='/warning.png' className='w-6' /> */}
                <svg
                  className="w-8"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M17,12c-2.76,0-5,2.24-5,5s2.24,5,5,5c2.76,0,5-2.24,5-5S19.76,12,17,12z M18.65,19.35l-2.15-2.15V14h1v2.79l1.85,1.85 L18.65,19.35z M18,3h-3.18C14.4,1.84,13.3,1,12,1S9.6,1.84,9.18,3H6C4.9,3,4,3.9,4,5v15c0,1.1,0.9,2,2,2h6.11 c-0.59-0.57-1.07-1.25-1.42-2H6V5h2v3h8V5h2v5.08c0.71,0.1,1.38,0.31,2,0.6V5C20,3.9,19.1,3,18,3z M12,5c-0.55,0-1-0.45-1-1 c0-0.55,0.45-1,1-1c0.55,0,1,0.45,1,1C13,4.55,12.55,5,12,5z"
                    fill="white"
                  ></path>
                </svg>
                <p className="text-white ml-4" style={{ fontSize: "15px" }}>
                  {/* Your mentor application is under review */}
                  Your mentor profile is under review
                </p>
                <div className="flex items-center justify-between pl-2">
                  <ReactTooltip />
                  <span
                    data-html={true}
                    data-tip={ReactDOMServer.renderToString(
                      <div>
                        <p>
                        Your profile will be made active on the platform once your experience and eduction with the relevant logos is completely filled out and a high quality profile picture is added
                        </p>
                      </div>
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            ) : null}
          </div>
          <div>
            {isProfileComplete?.isProfileComplete ? null : (
              <div className="bg-white rounded flex flex-col mb-5 border-red-300 border-2">
                {/* <img src='/warning.png' className='w-6' /> */}
                <div className="flex p-3">
                  <svg
                    className="w-8 h-10"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M0.311632 13.2715L7.20803 0.669112C7.2857 0.526895 7.40022 0.408214 7.53958 0.325533C7.67894 0.242851 7.83799 0.199219 8.00003 0.199219C8.16207 0.199219 8.32112 0.242851 8.46048 0.325533C8.59984 0.408214 8.71437 0.526895 8.79203 0.669112L15.6884 13.2715C15.7635 13.4088 15.8015 13.5632 15.7987 13.7196C15.7959 13.876 15.7524 14.029 15.6725 14.1635C15.5925 14.2979 15.4789 14.4093 15.3428 14.4864C15.2067 14.5636 15.0529 14.6039 14.8964 14.6035H1.10363C0.947191 14.6039 0.793341 14.5636 0.657253 14.4864C0.521166 14.4093 0.407543 14.2979 0.32759 14.1635C0.247636 14.029 0.204114 13.876 0.201316 13.7196C0.198518 13.5632 0.23654 13.4088 0.311632 13.2715ZM8.96003 8.60111L9.44363 5.69951C9.45792 5.6136 9.45332 5.5256 9.43017 5.44164C9.40702 5.35767 9.36587 5.27976 9.30958 5.2133C9.25328 5.14684 9.1832 5.09344 9.10418 5.0568C9.02517 5.02015 8.93913 5.00115 8.85203 5.00111H7.14803C7.06094 5.00115 6.97489 5.02015 6.89588 5.0568C6.81687 5.09344 6.74678 5.14684 6.69049 5.2133C6.63419 5.27976 6.59304 5.35767 6.56989 5.44164C6.54674 5.5256 6.54215 5.6136 6.55643 5.69951L7.04003 8.60111H8.96003ZM9.32003 10.8811C9.32003 10.531 9.18096 10.1953 8.93341 9.94773C8.68586 9.70018 8.35012 9.56111 8.00003 9.56111C7.64995 9.56111 7.3142 9.70018 7.06665 9.94773C6.8191 10.1953 6.68003 10.531 6.68003 10.8811C6.68003 11.2312 6.8191 11.5669 7.06665 11.8145C7.3142 12.062 7.64995 12.2011 8.00003 12.2011C8.35012 12.2011 8.68586 12.062 8.93341 11.8145C9.18096 11.5669 9.32003 11.2312 9.32003 10.8811Z"
                      fill="#F80000"
                    />
                  </svg>
                  <p className="text-black text-2xl ml-1">
                    Profile is incomplete
                  </p>
                </div>
                <p className=" text-base ml-16 font-normal ">
                  Your profile is incomplete and therefore not visible to
                  customers. Please complete your profile to start mentoring!
                </p>
                <p className=" text-base ml-16 font-normal ">
                  Sections incomplete:
                </p>
                <div className="flex flex-wrap ml-12 justify-start gap-2 p-4">
                  {!isProfileComplete?.Education ? (
                    <p className=" text-base font-normal font-bold">
                      • Education
                    </p>
                  ) : null}
                  {!isProfileComplete?.Experiences ? (
                    <p className=" text-base font-normal font-bold">
                      • Experience
                    </p>
                  ) : null}
                  {!isProfileComplete?.Availability ? (
                    <p className=" text-base font-normal font-bold">
                      • Availability
                    </p>
                  ) : null}
                  {!isProfileComplete?.JobTitle ? (
                    <p className=" text-base font-normal font-bold">
                      • Job Title
                    </p>
                  ) : null}
                  {!isProfileComplete?.Location ? (
                    <p className=" text-base font-normal font-bold">
                      • Location
                    </p>
                  ) : null}
                  {!isProfileComplete?.Prices ? (
                    <p className=" text-base font-normal font-bold">• Prices</p>
                  ) : null}
                  {!isProfileComplete?.ProfilePhoto ? (
                    <p className=" text-base font-normal font-bold">
                      • Profile Photo
                    </p>
                  ) : null}
                  {!isProfileComplete?.Bio ? (
                    <p className=" text-base font-normal font-bold">• Bio</p>
                  ) : null}
                </div>
              </div>
            )}
          </div>
        </>
      )}
      <div className="bg-white pb-8 mb-4 rounded">
        <Helmet>
          <title>Home | Dashboard | {CompanyTitle}</title>
          <meta
            name={`Home | Dashboard | ${CompanyTitle}`}
            content={`Home | Dashboard | ${CompanyTitle}`}
          />
        </Helmet>
        {isLoading ? (
          <div className="flex justify-center items-center pt-6">
            {" "}
            <Loader
              type="TailSpin"
              color={Colors.blue}
              height={40}
              width={40}
            />{" "}
          </div>
        ) : (
          <div>{ComponentToRender} </div>
        )}
      </div>
    </div>
  );
};

export default Home;
