import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HowItWorksReviews } from "../HowItWorks/HowItWorks";
import { getRefFromQuery } from "../CustomFunctions/CustomFunctions";
import { Mixpanel } from "../Mixpanel";
import Loader from 'react-loader-spinner';
import Colors from '../Colors/Colors'
import "./landing.css"
import {
  HowItWorksFindMentor,
  HowItWorksFourSteps,
  HowItWorksIcons,
  HowItWorksParagraphy,
  HowItWorksIconsSkeleton,
  HowItWorksParagraphySkeleton,
  HowItWorksFindMentorSkeleton,
  HowItWorksSkeleton
} from "../HowItWorks/HowItWorksMain";
import { HowItWorksReviewsSkeleton } from "../HowItWorks/HowItWorks";
import axios from "axios";
import Navbar from "../Navbar/Navbar";
import MainImage from "../mainImage/mainImage";
import Footer from "../Footer/Footer";
import { RecommendedMentors } from './RecommendMentors'
import { PopularMentors } from './PopularMentors'
import { Companies } from './Companies'
import { IndustriesCards } from './IndustriesCards';
import { MentorsInIndustries } from './MentorsInIndustries';
import PartnerComponent from '../roundedComponent/PartnerComponent'
import FeaturedSkeleton from "../Skeletons/featuredImagesSkeleton_landingPage";
import { GiftCards } from "./GiftCards";
const Landing = (props) => {
  // event slider logic - start
  // const [isEventsLoading, setIsEventsLoading] = useState(true);
  // const [events, setEvents] = useState([]);
  // const eventSkeletonArray = new Array(3).fill(1);

  // const getUpcomingEvents = () => {
  //   axios
  //     .get(`${process.env.REACT_APP_SERVER_PATH}/event`)
  //     .then((response) => {
  //       setTimeout(() => {
  //         setEvents(response.data?.rows);
  //         setIsEventsLoading(false);
  //       }, 1000);
  //     })
  //     .catch((e) => {
  //       console.log("Error in events API", e);
  //       setIsEventsLoading(false);
  //     });
  // };

  // useEffect(() => {
  //   getUpcomingEvents();
  // }, []);

  // event slider logic - end

  // courses logic - start
  const [isCoursesLoading, setIsCoursesLoading] = useState(true);
  const [courses, setCourses] = useState([]);


  const getCourses = () => {
    setTimeout(() => {
      setCourses([1]);
      setIsCoursesLoading(false);
    }, 100);
  };

  useEffect(() => {
    if (!localStorage.getItem("cp_web_specialty_model")) {
      localStorage.setItem("cp_web_specialty_model", 0);
    }
    getCourses();
  }, []);

  // courses logic - end

  let navigate = useNavigate();
  let queryString = useLocation();
  getRefFromQuery(queryString);
  const [isModelLoading, setModelLoading] = useState(false);
  const isLogin = true;
  const [userSelectedInterests, setUserSelectedInterests] = useState([]);
  const [userSelectedIndustries, setUserSelectedIndustries] = useState([]);

  const [isLoading, setLoading] = useState(true);
  setTimeout(() => {
    setLoading(false);
  }, 2000);
  const [showSpecialtyModel, setShowSpecialtyModel] = useState(false);
  const partner = [
    {
      profile_photo: "/oxford.png",
    },
    {
      profile_photo: "/cambridge.png",
    },
    {
      profile_photo: "/insead.jpeg",
    },
    {
      profile_photo: "/escp.jpg",
    },
    {
      profile_photo: "/per.png",
    },
    {
      profile_photo: "/jbm.png",
    },
    {
      profile_photo: "/finital.png",
    },
    {
      profile_photo: "/oneten.png",
    },
    {
      profile_photo: "/ef2.svg"
    },
    {
      profile_photo: "/growthrocks.png",
    },
    {
      profile_photo: "/linkedinPic.png",
    },
  ];
  const getQueryParam = (name) => {
    let match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, " "));
  };
  const StoreRef = () => {
    let reference = getQueryParam("ref");
    if (queryString.search.includes("ref")) {
      localStorage.setItem("cp_ref", reference);
      axios
        .get(
          `${process.env.REACT_APP_SERVER_PATH}/update-links-hits/${reference}`
        )
        .then((response) => {
          if (
            reference.toLowerCase() === "finatalJobs".toLowerCase() ||
            reference.toLowerCase() === "finatal".toLowerCase() ||
            reference.toLowerCase() !== "finatal".toLowerCase()
          ) {
            // navigate(`/welcome?org=Finatal`);
            navigate(`/`);
          } else if (
            reference.toLowerCase() === "linkedinJobs".toLowerCase() ||
            reference.toLowerCase() === "linkedIn".toLowerCase() ||
            reference.toLowerCase() === "linkedinpejobs".toLowerCase() ||
            reference.toLowerCase() === "linkedintechjobs".toLowerCase()
          ) {
            navigate(`/welcome?org=LinkedIn`);
          } else if (reference.toLowerCase() === "JBM".toLowerCase()) {
            navigate(`/welcome?org=JBM`);
          } else if (reference.toLowerCase() === "PER".toLowerCase()) {
            navigate(`/welcome?org=PER`);
          } else if (
            reference.toLowerCase() === "eFinancialcareers".toLowerCase()
          ) {
            navigate(`/welcome?org=eFinancialCareers`);
          }
        })
        .catch((e) => {
          console.log("Error Hitting Api of Reference");
        });
    }
  };

  // we get ref variable from url and check that it is available in the REACT_APP_EFC_INDUSTRIES_NAME array from .env
  // if reference is valid then navigate else remove item and return

  const getEFCperameters = () => {
    let reference = getQueryParam("ref");
    if (reference) {
      const index = process.env.REACT_APP_EFC_INDUSTRIES_NAME.split(',').indexOf(reference);

      if (index < 0 && reference != "EFCHOME" && reference != "EFCJOBS" && reference != "EFCEMAIL") {
        navigate('/');
        return;
      }
      localStorage.setItem("cp_ref", reference);
      axios.get(`${process.env.REACT_APP_SERVER_PATH}/update-links-hits/${reference}`)
        .then((response) => {
          // const industryId = process.env.REACT_APP_EFC_INDUSTRIES_ID.split(',')[index];
          const industrySlug = process.env.REACT_APP_EFC_INDUSTRIES_SLUG.split(',')[index];
          if (reference == "EFCHOME" || reference == "EFCJOBS" || reference == "EFCEMAIL") {
            // navigate('/');
            return
          }

          navigate(`/mentors/${industrySlug}`)


        })
        .catch((e) => {
          console.log("Error Hitting Api of Reference");
        });
    }
  }

  const getAllSelectedInterests = () => {
    axios.get(`${process.env.REACT_APP_SERVER_PATH}/get-user-interes/${+localStorage.getItem('id')}`).then((response) => {
      const { Interests } = response.data;
      if (Interests.length > 0) {
        let selectedIndustryCopy = [...userSelectedInterests]
        Interests.map((item) => {
          selectedIndustryCopy.push(item.id);
        })
        setUserSelectedInterests(selectedIndustryCopy)

      } else {

        setShowSpecialtyModel(true);
        setModelLoading(false)

      }
    }).catch(e => {
      console.log("error in industry api");
    })
  }

  const getAllSelectedIndustries = () => {
    axios.get(`${process.env.REACT_APP_SERVER_PATH}/customers/${+localStorage.getItem('id')}`).then((response) => {
      const { Industries } = response.data;
      if (Industries.length > 0) {
        const CustomerIndustries = Industries.map((industry) => {
          // Iterate over the Customer_Industries array nested inside the industry object
          return industry.Customers_Industries;
        });
        const IndustryID = CustomerIndustries.map((industry) => {
          return industry.industry_id;
        });
        setUserSelectedIndustries(IndustryID)
        // let selectedIndustryCopy = [...userSelectedIndustries]
        // Industries.map((item) => {
        //   selectedIndustryCopy.push(item.Customers_Industries);
        // })
        // setUserSelectedIndustries(selectedIndustryCopy)
      } else {
        setShowSpecialtyModel(true);
        setModelLoading(false)
      }
    }).catch(e => {
      console.log("error in industry api");
    })
  }



  const getCode = () => {
    const getQueryParamForAuthorization = () => {
      let match = RegExp("[?&]" + "code" + "=([^&]*)").exec(
        window.location.search
      );
      return match && decodeURIComponent(match[1].replace(/\+/g, " "));
    };
    if (getQueryParamForAuthorization() !== null) {
      const options = {
        method: "POST",
        url: `${process.env.REACT_APP_SERVER_PATH}/stripe/completeConnection`,
        params: { authorization_code: getQueryParamForAuthorization() },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cp_web_token")}`,
        },
      };

      axios
        .request(options)
        .then(function (response) {
          navigate("/dashboard/payments");
        })
        .catch(function (error) {
          console.log("Errors in customer Payment api", error);
        });
    }
  };

  const toggleAnonymous1 = () => {
    localStorage.setItem("cp_web_specialty_model", Number(localStorage.getItem("cp_web_specialty_model")) + 1)
    setShowSpecialtyModel(!showSpecialtyModel)
  }

  useEffect(() => {
    setModelLoading(true);
    getAllSelectedInterests();
    getAllSelectedIndustries();
    getCode();
    // StoreRef();
    getEFCperameters();

    window.scrollTo(0, 0);
    let cp_ref = localStorage.getItem("cp_ref");
    let cp_ref_count = localStorage.getItem("cp_ref_count");

    if (cp_ref && cp_ref_count == null) {
      Mixpanel.track("Landed on website", { ref_cp: cp_ref });
      localStorage.setItem("cp_ref_count", 1);
    }
  }, []);

  if (isLogin) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  return (
    <div className="bg-white">

      <Navbar />

      <hr />

      <MainImage />
      {isLoading ? (
        <div className="mt-6">
          <FeaturedSkeleton />
        </div>
      ) : (<div className="mt-6">
        {partner.length > 0 && (
          <PartnerComponent
            name="Our partners"
            partner={partner}
            len={partner.length}
          />
        )}
      </div>)}

      <div className="mt-8">
        {localStorage.getItem("cp_web_token") && userSelectedInterests.length > 0 ? (
          <RecommendedMentors
            userSelectedInterests={userSelectedInterests}
            userSelectedIndustries={userSelectedIndustries}
          />
        ) : (
          <PopularMentors />
        )}
      </div>
      <Companies />
      <IndustriesCards />
      <MentorsInIndustries />

      {showSpecialtyModel && localStorage.getItem("cp_web_specialty_model") < 3 && <AnonymousModal
        toggle={toggleAnonymous1}
        overlay="overlay"
        modal="modal"
        isAnonymous={true}
        isLoading={isModelLoading}
      />}
      <div className="mt-4">
        {isLoading ? (<HowItWorksSkeleton />) : (<HowItWorksFourSteps />)}
      </div>
      {isLoading ? (<HowItWorksIconsSkeleton />) : (<HowItWorksIcons />)}

      {isLoading ? (<HowItWorksParagraphySkeleton />) : (<HowItWorksParagraphy />)}

      {isLoading ? (<HowItWorksReviewsSkeleton />) : (<HowItWorksReviews />)}

      {isLoading ? (<HowItWorksFindMentorSkeleton />) : (<HowItWorksFindMentor />)}

      <Footer />
    </div>
  );
};
export default Landing;
const AnonymousModal = (props) => {
  let navigate = useNavigate();
  let type = localStorage.getItem("cp_web_token") ? JSON.parse(localStorage.getItem("type")) : "customer";
  return (
    <div className={props.modal} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '30px', zIndex: 999 }}>
      <div className={props.overlay}></div>
      <div className="flex flex-col items-center justify-center z-20 rounded-xl interest-Model p-14">
        {!props.isLoading ? <div>
          <div className='lg:w-80 sm:w-56 flex flex-col items-center justify-center '>
            <div className="w-full flex items-end justify-end -mt-9 cursor-pointer -mr-11" onClick={props.toggle}>
              <img src='/close-white-icon.png' className='w-7 h-7 rounded-full object-cover' alt='' />
            </div>
            <div className="flex items-center justify-center drop-shadow-lg rounded-lg m-2">
              <img src='/specialty-items.png' className='object-cover' alt='' />
            </div>
            <p className='lg:text-3xl sm:text-base font-extrabold text-center'>New Feature Alert!</p>
            <p className="lg:text-lg sm:text-base flex justify-center items-center font-bold m-3 drop-shadow-lg rounded-full border-2 pt-2 pb-1 pr-3 pl-3 py-0" style={{ color: '#00C4FF', borderColor: '#b9ebfa', backgroundColor: '#b9ebfa' }}>{type == 'customer' ? 'Interest' : 'Specialty'}</p>
            <p className='lg:text-lg sm:text-sm text-center lg:my-3 sm:my-1.5 w-72'>{type == 'customer' ? 'You now have the ability to tell CareerPaths what type of support you’re looking for!' : 'You now have the ability to tell CareerPaths users exactly how you can help them!'}</p>
            <p className='lg:text-lg sm:text-sm text-center lg:my-3 sm:my-1.5 w-72'>Select from options like <strong>interview prep, CV reviews, career advice </strong> and many more!</p>
          </div>
          <button className='w-full sm:text-base lg:text-lg flex justify-center items-center pt-3 pb-2 pr-2 pl-2 bg-bgAppColor text-white rounded-lg font-bold mb-2' onClick={() => {
            localStorage.setItem("cp_web_specialty_model", Number(localStorage.getItem("cp_web_specialty_model")) + 1)
            navigate('/dashboard/profile?toSpecialty=true');
          }}>{type == 'customer' ? 'Select "Interest" Now' : 'Select "Specialty" Now'}</button>
        </div> : <div className='w-full flex justify-center items-center h-full'>
          <Loader
            width={200}
            height={200}
            type="Oval"
            color={Colors.blue}
          />
        </div>}

      </div>
    </div>
  )
}

